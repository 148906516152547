import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Usuario, Login, datosUsuarioLogeado } from "src/app/models/usuario.model";
import { SidebarService } from "../sidebar/sidebar.service";
import { VariablesEnum } from "src/app/auth/enum/variables.enum";
import { LocalStorageService } from "src/app/services/localstorage.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariablesService } from '../../services/variableGL.service';
import { NotificacionModel } from "src/app/models/notificacion.model";
import { NotificacionesService } from "src/app/services/notificaciones.service";
import { RespuestaInformacionUsuario } from "src/app/models/villas/RespuestaInformacionUsuario.model";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @ViewChild('modalChangePass', { static: false }) modalChangePass: TemplateRef<any>;
  modalRefPass: any;

  authUs: RespuestaInformacionUsuario;
  nombreCompleto: string;
  modelResp: NotificacionModel[];
  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private variablesService: VariablesService,
    private notificacionServices: NotificacionesService
    ) {
      this.authUs = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));
      this.nombreCompleto = this.authUs.nombreUsuario;
    }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
    const logo = document.getElementById("logo-sidebar");
      if ($("#wrapper").hasClass("nav-collapsed")) {
          // unpin sidebar when hovered
          $("#wrapper").removeClass("nav-collapsed");
          $("#sidebar-wrapper").unbind( "hover");
          logo.style.marginLeft = "0px";
      } else {
          $("#wrapper").addClass("nav-collapsed");
          $("#sidebar-wrapper").hover(
              function () {
                  $("#wrapper").addClass("sidebar-hovered");
              },
              function () {
                  $("#wrapper").removeClass("sidebar-hovered");
              }
          )
          logo.style.marginLeft = "10px";

      }

  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {
    //THEMA 11
    $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme11');
    $('#active-class').attr('class', 'navigation active1');


  }

  Logout(){
    this.localStorageService.clearToken();
    this.router.navigate(['auth/signin']);
  }

  openModal(){
    this.variablesService.modalCambiaContrasena.next(true);
  }

}
