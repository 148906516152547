import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { DocumentoModel } from '../models/documento.model';

@Injectable({
  providedIn: 'root'
})
export class CargaArchivosService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

  agregar(objeto: any) {
    return this.postAsync(this.gatewayArchivos + 'AdminArchivos/adArchivos/Agregar/eb115e5c-1404-4ec5-a382-55a1ab8e018f', objeto);
  }

  obtenerArchivo(token: string) {
    return this.gatewayArchivos + 'AdminArchivos/adArchivos/Visor/eb115e5c-1404-4ec5-a382-55a1ab8e018f/' + token;
  }



}
