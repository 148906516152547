import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { Usuario, Login } from '../models/usuario.model';
import { RespuestaModel } from '../models/respuesta.model';
import { iDUsuarioLogeado } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class CMSUserService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }


  login(objeto: Login) : Promise<any> {
    return this.postAsync(this.gatewayApiLogin + 'Acceso/Boveda_Sign/Autenticar', objeto);
  }

  //VER QUE ENDPOINT VA A TRAER ESTA INFORMACION
  public async ObtieneDatosUsuarioLogeado(objeto: any )  : Promise<any>
  {
    return this.postAsync(this.serviciosEstanciasDeportivas + 'InformacionUsuario/Consultar', objeto);
  }


  public async InsertaUsuario(UsuarioInserta: any) : Promise<RespuestaModel>{
      return await this.postAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/InsertaUsuario', UsuarioInserta);
  }

  public async BajaUsuario(UsuarioBaja: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/BajaUsuario',UsuarioBaja);
  }

  public async CambiaPass(UsiarioModifcar: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/CambiaPass',UsiarioModifcar);
  }

  public async ObtieneUsuarios()  : Promise<RespuestaModel>
  {
    return await this.getAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/ObtieneUsuarios');
  }

  public async ObtieneUsuarioId(idUsuari:number)  : Promise<RespuestaModel>
  {
      return await this.getAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/ObtieneUsuarioId?IdUsuario='+idUsuari)
  }

  public async ModificaUsuario(UsiarioModifcar: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApiLogin+'Olimpiadas/Usuario/ModificaUsuario',UsiarioModifcar);
  }


}
