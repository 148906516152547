import { Roles } from "./roles.model";

export class Usuario{
  Accion: string;
  Id: number;
  Nombre: string;
  APaterno: string;
  AMaterno: string;
  Email: string;
  Contrasena: string;
  IdRol: number;
  Rol: Roles;
}


export class Login{
  us_password?:string;
  us_correo?:string;
  s_id?: number;
}

export class iDUsuarioLogeado{
  usId?: number;
  usCorreo?: number;
}

export class datosUsuarioLogeado{
  usId?:       number;
  usNombres?:  string;
  usPaterno?:  string;
  usMaterno?:  string;
  usCorreo?:   string;
}