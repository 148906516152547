<!-- Sidebar Header starts -->
<div id="sidebar-wrapper" class="sidebar bg-theme12">
    <div class="brand-logo">
        <a [routerLink]="['/']" (click)="toggleSidebar()">
            <img  class="logo-side" id="logo-sidebar" src="assets/images/logo_conade.png"  width="150px" height="50px" alt="logo icon" style="margin-left: 10px;">
        </a>
    </div>
    <div class="sidebar-header">
        <p class="mb-0" style="text-align: center;"></p>
      </div>
    <!-- Sidebar Header Ends -->

    <!-- Sidebar Content starts -->
    <div class="sidebar-content" [perfectScrollbar]>
        <ul id="active-class" class="navigation active4">
            <!-- First level menu -->
            <li *ngFor="let menuItem of menuItems"
                [ngClass]="[menuItem.class]"
                [routerLinkActive]="menuItem.submenu.length > 0 ? '' : 'active'" [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="menuItem.path !== '' ? [menuItem.path] : null" *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
                    <i [ngClass]="[menuItem.icon]"></i>
                    <span class="menu-title">{{menuItem.title}}</span>
                    <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                </a>
                <ng-template #externalLinkBlock>
                    <a [href]="[menuItem.path]" target="_blank">
                        <i [ngClass]="[menuItem.icon]"></i>
                        <span class="menu-title">{{menuItem.title}}</span>
                        <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                    </a>
                </ng-template>
                <!-- Second level menu -->

                <ul class="menu-content" *ngIf="menuItem.submenu.length >0">
                    <li *ngFor="let menuSubItem of menuItem.submenu"
                        [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'"
                        [ngClass]="[menuSubItem.class]">
                        <a
                            [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
                            *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                            <i [ngClass]="[menuSubItem.icon]"></i>
                            <span class="sub-menu-title">{{menuSubItem.title}}</span>
                            <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <ng-template #externalSubLinkBlock>
                            <a [href]="[menuSubItem.path]">
                                <i [ngClass]="[menuSubItem.icon]"></i>
                                <span class="sub-menu-title">{{menuSubItem.title}}</span>
                                <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul>

                <!-- <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">xD
                    <li *ngFor="let menuSubItem of menuItem.submenu" [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'" [ngClass]="[menuSubItem.class]">
                        <a [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]" *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                            <i [ngClass]="[menuSubItem.icon]"></i>
                            <span class="sub-menu-title">{{menuSubItem.title}}</span>
                            <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                        </a>
                        <ng-template #externalSubLinkBlock>
                            <a [href]="[menuSubItem.path]">
                                <i [ngClass]="[menuSubItem.icon]"></i>
                                <span class="sub-menu-title">{{menuSubItem.title}}</span>
                                <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                            </a>
                        </ng-template>
                    </li>
                </ul> -->
            </li>
        </ul>
    </div>
    <!-- Sidebar Content Ends -->
</div>
