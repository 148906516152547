<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">{{ tituloModal }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center" >{{ msjCuerpo }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">No</button>
    <button type="button" class="btn btn-primary" (click)="confirm()">Si</button>
  </div>
</div>
