import { Injectable } from '@angular/core';

import { VariablesEnum } from '../auth/enum/variables.enum';
import { StorageService } from './web-storage.service';


const SECRET_KEY = 'conade';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private storageService: StorageService
  ) {
  }

  setJsonValue(key: string, value: any) {
    this.storageService.setItem(key, value);
  }

  getJsonValue(key: string) {
    const session = this.storageService.getItem(key);
    return session;
  }

  clearToken() {
    this.storageService.clearToken();
  }

  guardarUsuarioActual(usuario: any) {
    return this.storageService.setItem(VariablesEnum.usuario, JSON.stringify(usuario));
  }

}
