import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalGenerico } from 'src/app/models/modalGenerico.model';

@Component({
  selector: 'app-componente-modal-dialogo',
  templateUrl: './componente-modal-dialogo.component.html',
  styleUrls: ['./componente-modal-dialogo.component.scss']
})
export class ComponenteModalDialogoComponent implements OnInit, OnDestroy  {

  public tituloModal = '';
  public msjCuerpo = '';

  constructor(private activeModal: NgbActiveModal) {
    this.tituloModal = localStorage.getItem('Titulo');
    this.msjCuerpo = localStorage.getItem('MsjCuerpo');
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.activeModal.close(true);
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  ngOnDestroy() {
    localStorage.removeItem('Titulo');
    localStorage.removeItem('MsjCuerpo');
  }
}
