import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaModel } from '../models/respuesta.model';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  // Start Servicios Villas
  gatewayEstanciasDeportivas = 'https://estanciasapi.conade.gob.mx//';
  /* serviciosEstanciasDeportivas = 'http://10.10.0.32:8521/'; */
  serviciosEstanciasDeportivas = 'https://estanciasapi.conade.gob.mx//';
  /* serviciosEstanciasDeportivas = 'https://localhost:7272/'; */
  serviciosEstanciasDeportivasLocal = 'https://estanciasapi.conade.gob.mx//';
  // Ends Servicios Villas
  gatewaycurp                = 'https://wsrenapo.conade.gob.mx/';
  gatewayArchivos            = 'https://adminarchivos.conade.gob.mx/'; /* PRODUCCION */
  /* gatewayArchivos            = 'http://10.10.0.32:8065/'; */       /* QA */
  gatewayApiLogin            = 'https://boveda.conade.gob.mx/';


  constructor(public httpClient: HttpClient) { }
  getAsync(url: string): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsync(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.post(url, objeto)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {
            subscription.unsubscribe();
            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  putAsync(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.put(url, objeto)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {
            subscription.unsubscribe();
            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  deleteAsync(url: string): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.delete<any>(url)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data ? data : { EXITO: true } as RespuestaModel);
          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }


  get() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/plantilla.json', { responseType: 'text' })
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  obtenerUsuariosCSV_() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/usuarios.json', { responseType: 'text' })
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsyncURL(url: string, objeto: any): Promise<any> {
    return new Promise(resolve => {
      const subscription = this.httpClient.post(url, objeto)
        .subscribe(
          data => {
            subscription.unsubscribe();
            resolve(data);
          }, error => {
            subscription.unsubscribe();
            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

}

