export class AdjuntoModel {
  id?: number;
  nombre?: string;
  tamanio?: string;
  tipo?: string;
  activo?: boolean;
  file?: any;
  token?: string;

  constructor() {
    this.id = 0;
    this.nombre = '';
    this.tamanio = '';
    this.tipo = '';
    this.activo = false;
    this.file = null;
    this.token = '';
  }
}
