import { RolesEnum } from 'src/app/auth/enum/RolesEnum';
import { RouteInfo } from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
      path: '/components/home',
      title: 'Home',
      icon: 'fas fa-home icon-cog1',
      class: '', badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      roles : [
        RolesEnum.RECEPCION_COMEDOR,
        RolesEnum.RECEPCION_INSTALACION,
        RolesEnum.CALIDAD,
        RolesEnum.ADMIN_ALBERGUE,
        RolesEnum.ADMIN
      ]
    },
    {
      path: '/components/solicitud/agregar', title: 'Nueva Solicitud', icon: 'fas fa-file icon-cog', class: '', badge: '', badgeClass: '',
      isExternalLink: false, submenu: [],
      roles : [
        RolesEnum.CALIDAD,
        RolesEnum.ADMIN
      ]
    },
    {
      path: '/components/lista-solicitud', title: 'Lista Solicitudes', icon: 'fas fa-list icon-cog', class: '', badge: '', badgeClass: '',
      isExternalLink: false, submenu: [],
      roles : [
        RolesEnum.CALIDAD,
        RolesEnum.ADMIN_ALBERGUE,
        RolesEnum.ADMIN
      ]
    },
    {
      path: '/components/lista-solicitud-respaldo', title: 'Respaldos Sol.', icon: 'fas fa-clipboard-list icon-cog',
      class: '', badge: '', badgeClass: '',
      isExternalLink: false, submenu: [],
      roles : [
        RolesEnum.CALIDAD,
        RolesEnum.ADMIN_ALBERGUE,
        RolesEnum.ADMIN
      ]
    },
    {
      path: '', title: 'Catalogos', icon: 'fa-solid fa-book-open', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        {
          path: '/components/habitaciones',
          title: 'Habitaciones',
          icon: 'fa-solid fa-bed-empty', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [
            RolesEnum.ADMIN_ALBERGUE,
            RolesEnum.ADMIN
          ]
        },
      ], roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
    },
    {
      path: '', title: 'Informes', icon: 'fas fa-file-alt icon-cog', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
      submenu: [
        /* {
          path: '/informes/servicios-otorgados',
          title: 'Servicios Otorgados',
          icon: 'fas fa-tags icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        }, */
        {
          path: '/informes/solicitudes',
          title: 'Solicitudes',
          icon: 'fas fa-tags icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        },
        {
          path: '/informes/alimentacion',
          title: 'Alimentacion',
          icon: 'fas fa-cutlery icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        },
        {
          path: '/informes/instalaciones',
          title: 'Instalaciones',
          icon: 'fas fa-dumbbell icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        },
        {
          path: '/informes/camas',
          title: 'Habitaciones',
          icon: 'fas fa-bed icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        },
        {
          path: '/informes/estancias',
          title: 'Estancias',
          icon: 'fas fa-bed icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        },
        {
          path: '/informes/delegaciones',
          title: 'Delegaciones',
          icon: 'fas fa-users icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
          roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
        }
      ], roles : [RolesEnum.ADMIN_ALBERGUE, RolesEnum.ADMIN]
    },

    {
      path: '/informes/alimentacion',
      title: 'Informe Alimentacion',
      icon: 'fas fa-cutlery icon-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
      roles : [RolesEnum.COCINA]
    }

    ,
    {
      path: '/components/validacion-acceso-instalacion', title: 'Validación acceso instalación', icon: 'fas fa-circle-check icon-cog',
      class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
      roles : [RolesEnum.RECEPCION_INSTALACION]
    },
    {
      path: '/components/validacion-acceso-comedor', title: 'Validación acceso comedor', icon: 'fas fa-circle-check icon-cog', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: [], roles : [RolesEnum.RECEPCION_COMEDOR]
    },
    {
      path: '/components/traza-delegacion', title: 'Traza delegación', icon: 'fas fa-route icon-cog', class: '', badge: '', badgeClass: '',
      isExternalLink: false, submenu: [],
      roles : [
        RolesEnum.RECEPCION_COMEDOR,
        RolesEnum.RECEPCION_INSTALACION,
        RolesEnum.ADMIN_ALBERGUE,
        RolesEnum.ADMIN
      ]
    },
    {
      path: '/components/cambio-estatus-delegacion', title: 'Cambio de estatus en delegación', icon: 'fas fa-arrows-rotate icon-cog',
      class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],
      roles : [
        RolesEnum.ADMIN_ALBERGUE,
        RolesEnum.ADMIN
      ]
    }
];
