import { Component, OnInit } from '@angular/core';
import { VariablesEnum } from 'src/app/auth/enum/variables.enum';
import { RespuestaInformacionUsuario } from 'src/app/models/villas/RespuestaInformacionUsuario.model';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { ROUTES } from './sidebar-routes.config';
import { SidebarService } from './sidebar.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: [`

    `],
     styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    authUs: RespuestaInformacionUsuario;
    constructor(
      public sidebarservice: SidebarService,
      private localStorageService: LocalStorageService,

    ) {

    }

    ngOnInit()
    {
      this.authUs = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));
        $.getScript('./assets/js/app-sidebar.js');
        //obtiene el comportamiento de menu
        this.menuItems = ROUTES.filter(menuItem => menuItem.roles.includes(this.authUs.catRolId));
        this.hover();
    }

    hover() {
      const logo = document.getElementById("logo-sidebar");
      $("#sidebar-wrapper").hover(
        function () {
          $("#wrapper").addClass("sidebar-hovered");
        },
        function () {
          $("#wrapper").removeClass("sidebar-hovered");
        }
      )
      logo.style.marginLeft = "10px";
    }


    toggleSidebar() {
      this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
      const logo = document.getElementById("logo-sidebar");
      if ($("#wrapper").hasClass("nav-collapsed")) {
        // unpin sidebar when hovered
        $("#wrapper").removeClass("nav-collapsed");
        $("#sidebar-wrapper").unbind("hover");


        logo.style.marginLeft = "0px";
      } else {
        $("#wrapper").addClass("nav-collapsed");
        /* $("#sidebar-wrapper").hover(
          function () {
            $("#wrapper").addClass("sidebar-hovered");
          },
          function () {
            $("#wrapper").removeClass("sidebar-hovered");
          }
        ) */
        this.hover();
        // logo.style.marginLeft = "10px";
      }
    }



}
